<template>
<!-- Navbar -->
<nav class="navbar navbar-expand-lg navbar-light bg-white">
    <div class="container-fluid">
      <button
        class="navbar-toggler"
        type="button"
        data-mdb-toggle="collapse"
        data-mdb-target="#navbarExample01"
        aria-controls="navbarExample01"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <i class="fas fa-bars"></i>
      </button>

      <div class="collapse navbar-collapse" id="navbarExample01">
        <ul class="navbar-nav me-auto mb-2 mb-lg-0">
          <li class="nav-item active">
            <a class="navbar-brand mt-2 mt-lg-0" href="#">
            <img
              src="https://cdn.simplexitydynamics.com/icons/s101/favicon-32x32.png"
              height="32"
              alt="Simplexity Dynamics Logo"
              loading="lazy"
            />
          </a>
          </li>
          <!--
          <li class="nav-item">
            <a class="nav-link" href="#">Features</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="#">Pricing</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="#">About</a>
          </li>
          -->
        </ul>
      </div>
 
    </div>
  </nav>
  <!-- Navbar -->

  <!-- Jumbotron -->
  
  <!-- <div class="bg-image1">
    <div class="p-5 text-center mask1">
      <h1 class="mb-3 text-light">Simplexity 101</h1>
      <h4 class="mb-3 text-light">Discovering Sustainable Solutions</h4>
      <p class="mb-3 text-light">
      &nbsp;
      </p>
    </div>
  </div> -->
  <div id="intro" class="ratio ratio-16x9">
          <video style="min-width: 100%; min-height: 100%;" playsinline autoplay muted loop>
            <source class="h-100" src="https://cdn.simplexitydynamics.com/vids/map.mp4" type="video/mp4" />
          </video>
          <div class="mask2">
            <div class="container d-flex align-items-center justify-content-center text-center h-100">
              <div class="text-white">
                <h1 class="mb-3">Simplexity 101</h1>
                <h5 class="mb-4">Discovering sustainable solutions through <br/>
                human centric design and Society 5.0.</h5>
                
              </div>
            </div>
          </div>
      </div>
  <!-- Jumbotron -->  
</template>
  
<script>
export default {
    name: 'HeaderMain',
    props: {
        msg: String
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
    margin: 40px 0 0;
}
ul {
    list-style-type: none;
    padding: 0;
}
li {
    display: inline-block;
    margin: 0 10px;
}
a {
    color: #42b983;
}
.bg-image1 {
    background-image: url('https://cdn.simplexitydynamics.com/images/s101/img_3593.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    /* background: linear-gradient(
        45deg,
        hsla(170, 15%, 30%, 0.7),
        hsla(263, 50%, 10%, 0.7) 70%
      ); */
}

.mask1 {
    background-color: rgba(21,15,15,.6)
}

@media (min-width: 992px) {
  #intro {
    /* margin-top: -58.59px; */
    margin-top: -5px;
  }
}

.bg-tn-1 {
  background: linear-gradient(
            90deg,
            rgba(20, 5, 43, 0.7),
            rgba(9, 21, 75, 0.301) 100%
          );
}

.mask2 {
  background: linear-gradient(
            45deg,
            rgba(64, 69, 73, 0.7),
            rgba(25, 16, 39, 0.7) 100%
          );
}
</style>
  