<template>



<div class="d-grid gap-3">
  <div>&nbsp;</div>
</div>  


<div class="container overflow-hidden text-center">

  <hr class="hr" />  

  <div class="d-grid gap-3">
    <div>&nbsp;</div>
  </div> 


  <div class="row"><h5 class="text-muted"><strong>Not affiliated with UN, but supporting its sustainability goals.</strong></h5></div>



  <section class="text-center">
          

          <div class="row align-items-center">
            <div class="bg-image hover-overlay ripple" data-mdb-ripple-color="light">
                  <img
                    src="https://cdn.simplexitydynamics.com/images/sdg/E-SDG-Poster%202019_without-UN%20emblem_WEB.png"
                    class="img-fluid "
                  />
                  
                
            </div>

            
          </div>
        </section>
        <!--Section: Content-->

        <div class="d-grid gap-3">
          <div>&nbsp;</div>
        </div>  
        <div class="d-grid gap-3">
          <div>&nbsp;</div>
        </div> 


        <hr class="hr" />

        <div class="d-grid gap-3">
          <div>&nbsp;</div>
        </div> 

        <!--Section: Content-->
        <section class="text-center">
          <div class="row">
            
            <div class="mb-5">
              <h4><strong>We are starting with Goal #3: Health and Well-Being.</strong></h4>
              <div class="bg-image hover-overlay ripple" data-mdb-ripple-color="light">
                  <img
                    src="https://cdn.simplexitydynamics.com/images/sdg/SDG_Icons_Inverted_Transparent_WEB-03.png"
                    class="img-fluid img-small"
                  />
                  
                </div>
              
            </div>
          </div>
        </section>
        <!--Section: Content-->


</div>


<div class="d-grid gap-3">
  <div>&nbsp;</div>
</div>  

</template>

<script>
export default {
  name: 'HomePage',
  props: {
    msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
.img-small {
    max-width: 25%
}

</style>